.main-app {
    width: 100%;
    height: 100vh;
    display: flex;
    overflow: hidden;
}

.main-app-right-part {
    width: calc(100% - 220px);
}

.main-menu {
    height: 100%;
    width: var(--menu-size);
    position: relative;
}

.app-logo,
.main-navbar {
    height: 80px;
    border-bottom: 1px solid var(--black-10);
}

.main-navbar {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px;
}

.app-logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-container {
    height: calc(100% - 110px);
    overflow-y: scroll;
}

.menu-container::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.menu-item {
    height: 55px;
    display: flex;
    align-items: center;
    padding-left: 54px;
    text-decoration: none;
    color: var(--black-100);
    font-weight: 600;
    transition: all .1s;
    position: relative;
    z-index: 0;
}

.menu-item:before {
    content: '';
    position: absolute;
    left: 17px;
    top: 16px;
    width: 22px;
    height: 22px;
    background: url('../icons/projects.svg') no-repeat;
    background-size: contain;
}

.menu-item.accounting:before {
    background: url('../icons/coins--blue.svg') no-repeat;
    background-size: contain;
}

.menu-item.google:before {
    background: url('../icons/globe--blue.svg') no-repeat;
    background-size: contain;
}

.menu-item.tests:before {
    background: url('../icons/briefcase--blue.svg') no-repeat;
    background-size: contain;
}

.menu-item.projects:before {
    background: url('../icons/dashboard--blue.svg') no-repeat;
    background-size: contain;
}

.menu-item.inventaire:before {
    background: url('../icons/inventaire--blue.svg') no-repeat;
    background-size: contain;
}

.menu-item.chatbot:before {
    background: url('../icons/chatbot--blue.svg') no-repeat;
    background-size: contain;
}

.menu-item.holidays:before {
    background: url('../icons/umbrella-beach--blue.svg') no-repeat;
    background-size: contain;
}

.menu-item.has-sub-items:hover {
    background: var(--white-100);
}

.menu-item.active.accounting:before {
    background: url('../images/qonto-logo.png') no-repeat;
    background-size: contain;
}

.menu-item.orders:before {
    background: url('../icons/orders.svg') no-repeat;
    left: 23px;
}

.menu-item.customers:before {
    background: url('../icons/customers--black.svg') no-repeat;
    width: 25px;
    height: 26px;
    top: 27px;
}

.menu-item.suppliers:before {
    background: url('../icons/suppliers.svg') no-repeat;
    width: 25px;
    height: 26px;
    top: 27px;
}

.menu-item:hover,
.menu-item.active {
    background: var(--black-5);
}

.menu-link:hover {
    text-decoration: none;
}

.main-menu-container {
    height: 100%;
    position: relative;
    border-right: 1px solid var(--black-10);
}

.searchbar-container {
    width: 100%;
}

.searchbar-item input {
    height: 40px;
    width: 100%;
    border: 1px solid var(--black-15);
    background-color: var(--black-5);
    padding: 0 20px 0 40px;
    font-weight: 500;
    font-size: .875rem;
}

.searchbar-item {
    position: relative;
}

.searchbar-item:before {
    content: '';
    position: absolute;
    left: 12px;
    top: 12px;
    width: 18px;
    height: 22px;
    background: url('../icons/search.svg') no-repeat;
    background-size: contain;
}

.user-info-hr-vertical {
    width: 1px;
    height: 1.5rem;
    background-color: var(--black-10);
}

.user-info-hr-horizontal {
    width: 100%;
    height: 1px;
    background-color: var(--black-10);
    margin-block: 1rem;
}

.user-item {
    height: 40px;
    background-color: var(--black-5);
    border: 1px solid var(--black-10);
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9em;
    font-weight: 700;
    padding-inline: 0.625rem;
    white-space: nowrap;
    position: relative;
    cursor: pointer;
    transition: all .3s;
    color: var(--black-100);
}

.user-item:hover {
    border-color: var(--black-50);
}

/* 
.user-item:hover:before {
    background: url('../icons/account-white.svg') no-repeat;
}

.user-item:hover:after {
    background: url('../icons/arrow-toggle-white.svg') no-repeat;
} */

/* .user-item:before {
    content: '';
    position: absolute;
    left: 12px;
    top: 9px;
    width: 17px;
    height: 22px;
    background: url('../icons/account--white.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
} */

/* .user-item:after {
    content: '';
    position: absolute;
    right: 12px;
    top: 16px;
    width: 15px;
    height: 22px;
    background: url('../icons/arrow-toggle.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
} */

.user-options a {
    text-decoration: none;
    color: var(--blue-100);
}

.user-options {
    display: flex;
    /* width: 260px; */
    padding: 0.625rem;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0.625rem;
    border: 1px solid var(--Grey-100, #DDD);
    background: var(--White-500, #FFF);
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
}

.user-options-link {
    width: 100%;
}

.user-options-item {
    height: 40px;
    border-radius: 0.5rem;
    width: 100%;
    background: var(--white-100);
    padding: 14px 8px;
    color: var(--black-100);
    font-size: .875rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: relative;
    cursor: pointer;
    transition: all .3s;
    text-decoration: none;
}

.user-options-item:hover {
    background: var(--black-10);
    color: var(--black-100);
}

/* .user-options-item:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1.125rem;
    height: 1.125rem;
    background: url('../icons/settings.svg') no-repeat;
    background-size: contain;
} */

.user-options-item.warning {
    border: 1px solid var(--orange-60);
    color: var(--orange-120);
}

.user-options-item.warning:hover {
    background: var(--orange-30);
}

.user-options-item.users:before {
    background: url('../icons/users--blue.svg') no-repeat;
    background-size: contain;
}

.user-options-item.roles:before {
    background: url('../icons/key--blue.svg') no-repeat;
    background-size: contain;
}

.user-options-item.update:before {
    background: url('../icons/refresh--blue.svg') no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
    left: 13px;
    top: 11px;
}

.user-options-item.update.warning:before {
    background: url('../icons/refresh--orange.svg') no-repeat;
    background-size: contain;
}

.user-options-item.launch {
    border: 1px solid var(--green-100);
    color: var(--green-100);
}

.user-options-item.launch:hover {
    color: white;
    background: var(--green-100);
}

.user-options-item.logout,
.user-options-item.delete {
    border: 1px solid var(--red-100);
    color: var(--red-100);
}

.user-options-item.logout:before {
    background: url('../icons/logout--red.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
    top: 11px;
    left: 14px;
    width: 18px;
}

.user-options-item.logout:hover,
.user-options-item.delete:hover {
    background: var(--red-100);
    color: var(--white-100);
}

.user-options-item.logout:hover:before {
    background: url('../icons/logout--white.svg') no-repeat;
    background-size: contain;
}

.user-options-item.launch:hover:before {
    background: url('../icons/launch--white.svg') no-repeat;
    background-size: contain;
}

.user-options-item.link:before {
    background: url('../icons/link-alt--blue.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
    height: 18px;
    top: 0.625rem;
}

.user-options-item.launch:before {
    background: url('../icons/launch--green.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
    height: 20px;
    top: 9px;
}

.user-options-item.delete:before {
    background: url('../icons/delete--red.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
    top: 11px;
    left: 13px;
    width: 16px;
}

.user-options-item.delete:hover:before {
    background: url('../icons/delete--white.svg') no-repeat;
    background-size: contain;
}

.user-options-item.archive:before {
    background: url('../icons/archive--blue.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
    top: 12px;
    left: 12px;
    width: 19px;
}

.menu-sub-item {
    padding: 0 19px 0 54px;
    position: relative;
}

.menu-sub-item span {
    display: block;
    padding: 4px 10px;
    border-radius: 0.5rem;
    margin-bottom: 4px;
    color: var(--black-70);
    font-size: .9em;
    transition: all .3s;
    font-weight: 500;
}

.menu-sub-item span:hover,
.menu-sub-item.active span {
    background-color: var(--blue-10);
    color: var(--blue-100);
}

.menu-sub-item .sub-item-info-count {
    position: absolute;
    top: 5px;
    right: 24px;
    background: var(--blue-5);
    font-size: .8em;
    width: fit-content;
    padding: 2px 6px;
    height: 19px;
    border-radius: 5px;
    font-weight: 700;
    color: var(--blue-100);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
}

.menu-sub-item:hover .sub-item-info-count,
.menu-sub-item.active .sub-item-info-count {
    background: var(--white-100);
}

.menu-sub-item .sub-item-info-count span {
    background: transparent;
    padding: 0;
    margin: 3px 0;
}

.menu-sub-item .sub-item-info-count.valid {
    background: var(--green-30);
    color: var(--green-170);
}

.menu-sub-item .sub-item-info-count.invalid {
    background: var(--red-30);
    color: var(--red-170);
}

.menu-link:last-child {
    margin-bottom: 20px;
}

.menu-link:last-child:before {
    height: 100%;
}

.menu-sub-item:before {
    content: '';
    position: absolute;
    top: 0;
    left: 27px;
    width: 2px;
    height: calc(100% + 4px);
    background: var(--black-5);
}

.user-item span {
    display: flex;
    align-items: center;
    justify-content: center;
}

.version-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid var(--black-5);
    font-size: .9em;
    text-align: center;
    font-weight: 400;
    height: 30px;
    color: var(--black-60);
    font-style: italic;
    display: flex;
    align-items: center;
    justify-content: center;
}